import React, { Component } from 'react';
//        <video class="video" src="https://cdn.discordapp.com/attachments/1050552088779825176/1105392794907988049/video1.mp4" playsInline controls></video>


class Video extends Component {


  render() {

    return (

      <div class="videoContainer">

        <video class="video" src="https://cdn.discordapp.com/attachments/1050552088779825176/1105394888134119485/video2.mp4" playsInline controls></video>

      </div>)
  }
}

export default Video;
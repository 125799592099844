import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const buy = () => {
    window.open("https://pancakeswap.finance/swap?outputCurrency=0x8338a17F65D99E286c240A9C08A5A4bAaa19Dc56");
}

class Token extends Component {

    render() {
        return (

            <div class="boxWrap2Token">

                <div class="storyH" >Tokenómica</div>
                <p class="tokenCon" >Queremos mostrar nuestro compromiso con el proyecto, para asegurar la tranquilidad de nuestros inversores, el 90% de los fondos recaudados durante la preventa se destinará directamente al pool de liquidez que estará bloqueado durante un período de un año. Los tokens del equipo se liberarán gradualmente a partir del segundo mes.</p>
                <div class="tokenM" >
                    <div class="storyConT">
                        <video class="tokenV2" src="https://cdn.discordapp.com/attachments/1050552088779825176/1105475998834765824/POSTEE.mp4" playsInline controls></video>

                        <p></p>
                        <p class="tokenConDiv">
                            <div class="tokenHSub">Suministro Total 1,000,000,000 POST:</div>
                            <div class="tokenH2">El suministro total es de 1 mil millones de tokens POST, nunca se crearán más tokens.</div>
                        </p>

                        <p class="tokenConDiv">
                            <div class="tokenHSub">Preventa 353.500.000 POST Durante el período de lanzamiento justo, se pusieron a disposición 353.500.000 POST:</div>
                            <div class="tokenH2">tokens, lo que permitió a los primeros seguidores participar en el éxito del proyecto.</div>
                        </p>

                        <p class="tokenConDiv">
                            <div class="tokenHSub">Dirección del Contrato de Liquidez de Intercambio 201,495,000 POST Un total de 201,495,000 POST:</div>
                            <div class="tokenH2">tokens se asignaron para la liquidez del intercambio, asegurando la disponibilidad y accesibilidad del token en el mercado.</div>

                        </p>

                        <p class="tokenConDiv">
                            <div class="tokenHSub">Nuevos Pools de Liquidez: 145.005.000 POST:</div>
                            <div class="tokenH2">Parte de los ingresos se utilizará para agregar pools de liquidez, se han reservado 145,005,000 tokens para este propósito. Se agregarán a los nuevos pares de intercambio.</div>
                        </p>

                        <p class="tokenConDiv">
                            <div class="tokenHSub">Quemados 100,000,000 POST:</div>
                            <div class="tokenH2">Se quemaron 100,000,000 de tokens para fortalecer aún más el valor de los tokens de los inversores.</div>
                        </p>

                        <p class="tokenConDiv">
                            <div class="tokenHSub">Marketing 100,000,000 POST</div>
                            <div class="tokenH2">Se han reservado 100 millones de tokens POST para inversiones futuras en marketing.</div>
                        </p>

                        <p class="tokenConDiv">
                            <div class="tokenHSub">Listado en CEX 100,000,000 POST</div>
                            <div class="tokenH2">Una parte del suministro de tokens, 100 millones de tokens POST, se han reservado para su listado en exchanges centralizados, aumentando la accesibilidad y exposición a una audiencia más amplia.</div>
                        </p>

                        <p class="tokenConDiv">
                            <div class="tokenHSub" onClick={buy}><span class="buy">Comprar fichas POST</span></div>
                        </p>
                    </div>
                    <video class="tokenV" src="https://cdn.discordapp.com/attachments/1050552088779825176/1105475998834765824/POSTEE.mp4" playsInline controls></video>

                </div>

            </div>
        )
    }
}

export default Token;


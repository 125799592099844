import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class TokenBenefits extends Component {

    render() {
        return (

            <div class="boxWrap2TB">

<div class="storyH" >Beneficios del token</div>
             <div class="tokenM2">
                <div class="storyConT2">
                    <p></p>
                    <p class="tokenConDiv2" >
                        <div class="tokenHSub">Potencial de crecimiento a largo plazo:</div>
                        <div class="tokenH2">Al tener el token POST, los inversores pueden beneficiarse del potencial de crecimiento a largo plazo a medida que el proyecto se expande a más ubicaciones y gana más usuarios.</div>
                    </p>

                    <p class="tokenConDiv2" >
                        <div class="tokenHSub">Acceso a NFTs exclusivos:</div>
                        <div class="tokenH2">A medida que el proyecto crece, también lo hará la selección de NFTs únicos y exclusivos que solo se pueden obtener a través de los dispositivos POST. Al tener el token POST, los inversores tendrán ciertas ventajas.</div>
                    </p>

                    <p class="tokenConDiv2" >
                        <div class="tokenHSub">Suministro limitado de tokens:</div>
                        <div class="tokenH2">Con un suministro limitado de tokens de solo 1,000,000,000, el valor del token POST es una cantidad fija de tokens, no se generarán más tokens en el futuro.</div>

                    </p>

                    <p class="tokenConDiv2" >
                        <div class="tokenHSub">Equipo experimentado:</div>
                        <div class="tokenH2">El equipo detrás del proyecto POST está formado por ingenieros de ciencias de la computación con experiencia en el mundo de los NFTs. Esto brinda un nivel de confianza y seguridad en la capacidad del proyecto para cumplir con sus promesas.</div>
                    </p>

                    <p class="tokenConDiv2" >
                        <div class="tokenHSub">Logros:</div>
                        <div class="tokenH2">Los logros se habilitarán en forma de NFTs que se otorgarán a los usuarios que cumplan una serie de condiciones, como visitar más de 10 países, un país en cada continente, etc.</div>
                    </p>
                                           
                </div>

            </div>
 
            </div>
        )
    }
}

export default TokenBenefits;


import './App.css';
import React, { Component } from 'react';
import Mission from "./components/mission";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Roadmap from './components/roadmap';
import FAQ from './components/faq';
import Footer from './components/footer';
import logo from './assets/Logo200.png';
import mintPic from './assets/eiffel.jpeg';
import Token from './components/token';
import TokenBenefits from './components/tokenBenefits';
import up from './assets/arrow-up.png';
import $ from "jquery";
import Ticker from './components/ticker';
import NFTproject from './components/nftProject'
import Video from './components/video'
import navbar from './assets/navigation-bar.png';
import wrong from './assets/wrong.png';
import downArrow from './assets/downArrow.png';
import man2 from './assets/man2.png';
import translate from './assets/translate.png';
import post from './assets/post.png';
import bnb from './assets/binance.png';
import busd from './assets/coin.png';
import layers from './assets/layers.png';

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;


var nft1;
var nft2;
var nft3;

var src1;
var src2;
var src3;
var src4;
var src5;
var src6;
var src7;
var src8;
var src9;
var src10;
var src11;
var src12;

src1 = './assets/1.png';
src2 = './assets/2.png';
src3 = './assets/3.png';
src4 = './assets/4.png';
src5 = './assets/5.png';
src6 = './assets/6.png';
src7 = './assets/7.png';
src8 = './assets/8.png';
src9 = './assets/9.png';
src10 = './assets/10.png';
src11 = './assets/11.png';
src12 = './assets/12.png';

$(document).ready(function () {
	const stickyButton = $("#sticky-button");
	$(window).on("scroll", function () {
		if ($(this).scrollTop() > 200) {
			stickyButton.css("visibility", "visible");
		} else {
			stickyButton.css("visibility", "hidden");
		}
	});
});

const wp = () => {
	window.open("https://docs.postee.site/?_gl=1*17k8973*_ga*MTczNDUyNDc5Mi4xNjgyODU3NTI1*_ga_3Z9GWMHQ5T*MTY4MzYxOTcxMi4xOC4wLjE2ODM2MTk3MTIuMC4wLjA.*_ga_F28XFS9J7V*MTY4MzYxOTcxMy4xOC4wLjE2ODM2MTk3MTMuMC4wLjA.&_ga=2.269067830.654906178.1683535721-1734524792.1682857525");
}

const website = () => {
	window.open("https://postee-en.pages.dev");
}


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let public_sale_cost;
let presalesale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;
let presale_status = false;
let translateWindow = 0;

// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses = [

];

let whitelistAddresses2 = [];

// 3. Create a new array of `leafNodes` by hashing all indexes of the `whitelistAddresses`
// using `keccak256`. Then creates a Merkle Tree object using keccak256 as the algorithm.
//
// The leaves, merkleTree, and rootHas are all PRE-DETERMINED prior to whitelist claim
const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

const leafNodes2 = whitelistAddresses2.map(addr2 => keccak256(addr2));
const merkleTree2 = new MerkleTree(leafNodes2, keccak256, { sortPairs: true });

// 4. Get root hash of the `merkleeTree` in hexadecimal format (0x)
// Print out the Entire Merkle Tree.
const rootHash = merkleTree.getRoot();
const rootHashHash = merkleTree.getHexRoot();

const rootHash2 = merkleTree2.getRoot();
const rootHashHash2 = merkleTree2.getHexRoot();

const ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0x3f9A24Ad492797ef36eC9EEec629E2D8Cd7593f1";
let contract;


class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Whitelist Mint',
		_public_sale_cost: '',
		_presalesale_cost: '',
		_MAX_PER_WALLET: '',
		_public_mint_status: '',
		_presale_status: '',
		_cost: '',
		_mintStatus: false,
		gallery: '',
		_navbarOpen: 0,
		_translateWindow: 0
	}

	closeNav = () => {
		const navBar = document.querySelector(".littleNav");
		navBar.classList.add("hidden");
	}

	closeNav = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 0 });
	}


	async componentDidMount() {

		try {
			
			if (localStorage?.getItem('src') == "1") {
				localStorage.setItem('src', "2");

				this.setState({ gallery: "1" });

			} else

				if (localStorage?.getItem('src') == "2") {
					localStorage.setItem('src', "3");

					this.setState({ gallery: "2" });

				} else

					if (localStorage?.getItem('src') == "3") {
						localStorage.setItem('src', "4");

						this.setState({ gallery: "3" });

					} else

						if (localStorage?.getItem('src') == "4") {
							localStorage.setItem('src', "1");

							this.setState({ gallery: "4" });

						} else {

							localStorage.setItem('src', "1");
							this.setState({ gallery: "1" });

						}


		} catch (err) {
			console.log(err);
		}

	}


	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}


		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 1000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {


			if (totalSupplyNFT < 1000) {

				onlyLeft = MAX_PER_WALLET - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;


				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 1000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;


			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 1000) {

				onlyLeft = MAX_PER_WALLET - publicSale;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}



	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 685000, from: account, value: this.state.totalValue * 1 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 685000, from: account, value: this.state.totalValue * 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}






			}
		} catch (err) {

			console.log(err);

		}
	}


	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {

						const chainId = 56;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}





						try {

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);
							//..........................................................................//
							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 1000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 1000) {

									onlyLeft = 1000 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///................................///



							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					} else {

						const chainId = 56;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}




						try {

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);



							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 1000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;

								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 1000) {

									onlyLeft = 0;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///................................///



							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload();

	}

	whitelistMint = async event => {
		event.preventDefault();


		//	console.log('Whitelist Merkle Tree\n', merkleTree.toString());
		console.log("Root Hash: ", rootHash);
		console.log("Root HashHash: ", rootHashHash);

		// ***** ***** ***** ***** ***** ***** ***** ***** // 

		// CLIENT-SIDE: Use `msg.sender` address to query and API that returns the merkle proof
		// required to derive the root hash of the Merkle Tree

		// ✅ Positive verification of address
		//const claimingAddress = leafNodes[0];
		//console.log("Claiming Address:"+ claimingAddress);
		// ❌ Change this address to get a `false` verification
		const claimingAddress = keccak256(account);

		// `getHexProof` returns the neighbour leaf and all parent nodes hashes that will
		// be required to derive the Merkle Trees root hash.
		const hexProof = merkleTree.getHexProof(claimingAddress);
		console.log("HexProof:" + hexProof);

		// ✅ - ❌: Verify is claiming address is in the merkle tree or not.
		// This would be implemented in your Solidity Smart Contract
		console.log("Final result: " + merkleTree.verify(hexProof, claimingAddress, rootHash));
		FinalResult = merkleTree.verify(hexProof, claimingAddress, rootHash);
		this.setState({ _FinalResult: FinalResult });

		if (FinalResult) {
			try {
				this.setState({ statusError: false, statusLoading: true });
				await contract.methods.whitelistMint(hexProof, wMintAmount).send({ gasLimit: 285000, from: account, value: wMintAmount * 12000000000000000 });
				this.setState({ statusLoading: false, success: true });
				console.log("Mint Amount :" + this.state.wMintAmount);

			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);
			}
		} else {
			wlMsg = "Not Eligible for WL"
			this.setState({ _wlMsg: wlMsg });
		}

	}

	navbarOpen = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 1 });
	}

	translateWindow = async event => {
		event.preventDefault();

		this.setState({ _translateWindow: 1 });
	}

	translateWindowClose = async event => {
		event.preventDefault();

		this.setState({ _translateWindow: 0 });
	}

	render() {
		const { languageCode } = this.state;

		return (

			<div class="allWrap">
				<div class="light">

					{this.state._navbarOpen < 1 ?
						(

							<div class="miniBarMain">
								<div class="logo2"><img src={logo} /></div>

								<div class="navbarMain"><img class="navbar" onClick={this.navbarOpen} src={navbar} /></div>
							</div>) :
						(<div class="miniBarMain">
							<div class="logo2"><img src={logo} /></div>

							<div class="navbarMain"><img class="navbar" onClick={this.closeNav} src={wrong} /></div>
						</div>)}

					<Element name="headers">
						<div class="headers">

							<div class="h1">

								<div class="topLogo">

									<Link to="gallery" spy={true} smooth={true} duration={550}><div ><img class="logoMobile" src={logo} /></div></Link>

									<div class="iconsMob">

										<div class="connect2Mobile">
											{this.state.walletAddress === '' ?
												(<form class="connect2Mobile" onSubmit={this.walletConnect}>
													<button class="connectBtn" disabled>WALLET CONNECT</button>
												</form>) : (<form class="connect2Mobile" onSubmit={this.walletDisconnect}><button class="connectBtn" >
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

										</div>
									</div>
								</div>
								<div class="connect">
    								<Link to="gallery" spy={true} smooth={true} duration={550}><div ><img class="logo" src={logo} /></div></Link>
    								<div ><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>Acerca de</Link></div>
    								<div ><Link activeClass="" id="fontSize" to="mission" spy={true} smooth={true} duration={550}>Misión</Link></div>
    								<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>Mint</Link></div>
    								<div ><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>Preguntas frecuentes</Link></div>
    								<div ><Link activeClass="" id="fontSize" to="rm" spy={true} smooth={true} duration={550}>Hoja de ruta</Link></div>
    								<div id="fontSize" onClick={wp}>Libro blanco</div>
    							</div>

								<div class="right">
									<div class="icons">

										<div class="connect2">
											{this.state.walletAddress === '' ?
												(<form class="connect2" onSubmit={this.walletConnect}>
													<button class="button-29" disabled>WALLET CONNECT</button>
												</form>) : (<form class="connect2" onSubmit={this.walletDisconnect}><button class="button-29" >
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

										</div>
									</div>
								</div>

							</div>

						</div>
					</Element>

					{this.state._navbarOpen > 0 ?
						(<div class="littleNav" data-aos="fade-left">

							<div ><Link activeClass="" id="fontSize2" to="about" spy={true} smooth={true} duration={550}>About</Link></div>
							<div ><Link activeClass="" id="fontSize2" to="mission" spy={true} smooth={true} duration={550}>Mission</Link></div>
							<div ><Link activeClass="" id="fontSize2" to="mint" spy={true} smooth={true} duration={550}>Mint</Link></div>
							<div ><Link activeClass="" id="fontSize2" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>
							<div ><Link activeClass="" id="fontSize2" to="rm" spy={true} smooth={true} duration={550}>Roadmap</Link></div>
							<div id="fontSize" onClick={wp}>Whitepaper</div>
						</div>) : null}


					<div class="cont">
			
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>
						<div class="circle-container2">
							<div class="circle"></div>
						</div>


						<div class="introduction">

							<div class="introT1">Postee</div>

							<div class="container">

								<video class="vid1" src='https://cdn.discordapp.com/attachments/1050552088779825176/1105402255382945902/3DAnimationLogo_Diamond.mp4' autoPlay loop playsInline muted></video>
								<video class="vid2" src='https://cdn.discordapp.com/attachments/1050552088779825176/1105402146721103882/3DAnimationLogo_Gold.mp4' autoPlay loop playsInline muted></video>
								<video class="vid3" src='https://cdn.discordapp.com/attachments/1050552088779825176/1105401626971361340/3DAnimationLogo_Bronze.mp4' autoPlay loop playsInline muted></video>

							</div>
						</div>
					</div>

					<Ticker />

					<Element name="mission">
						<Mission />
					</Element>
					<Element name="about">
						<div class="introduction2" >

							<div>
								<div class="introT">Conoce acerca de Postee</div>
								<p>Postee es un proyecto que revolucionará el mundo de los recuerdos y se convertirá en la plataforma de referencia principal para momentos digitales. Con Postee podrás construir una colección de NFTs de todos los países y lugares que hayas visitado y compartirlos en tus redes sociales. Postee se convertirá en tu pasaporte digital.</p>
								<button>Mint Now</button>
								<div class="downArrowDiv"><img class="downArrow" src={downArrow} /></div>
							</div>

							<img class="layers" src={layers} />

						</div>

					</Element>
					<Ticker />

					<Element name="mint">
						<div class="mintDiv">
							<div class="pDiv"><img class="parrot" src={mintPic} /></div>
							<div class="mintCon">
								<div class="totalSupply">{this.state.totalSupply} / 10,000</div>
								<div class="price"><div>Precio de creación: 10 BUSD</div></div>

								<div class="minting_count_button">
									<div class="center">
										<form onSubmit={this.onSubmitMinus}>
											<button class="btnfos-0-2" type="submit">-</button>
										</form>
									</div>

									<div>
										<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
									</div>

									<div class="center">
										<form onSubmit={this.onSubmitPlus}>
											<button class="btnfos-0-2-2" type="submit">+</button>
										</form>
									</div>
								</div>

								{this.state.walletAddress === '' ?

									(<div class="mintbuttondiv">
										<form onSubmit={this.walletConnect}>
											<button class="btnfos-0-3" disabled>¡Próximamente!</button>
										</form></div>) :
									(<div class="mintbuttondiv">
										<form onSubmit={this.onSubmit2}>
											<button class="btnfos-0-3" type="submit">
												Crear con BNB<img class="currency" src={bnb} /></button>
										</form>

										<form onSubmit={this.onSubmit2}>
											<button class="btnfos-0-3" type="submit">
												Crear con BUSD<img class="currency" src={busd} /></button>
										</form>

										<form onSubmit={this.onSubmit2}>
											<button class="btnfos-0-3" type="submit">
												Crear con POST<img class="currency" src={post} /></button>
										</form>
									</div>)}
								<div>

									{this.state.statusError ? (
										<div class="errorMessage">
											<div>Lo sentimos, algo salió mal. Por favor, inténtalo de nuevo más tarde.</div>
										</div>)
										: null}

									{this.state.statusLoading ? (
										<div class="loadingContainer">
											<div>
												<div class="loadingText">Creando tu Postee</div>
											</div>
										</div>)
										: null}

									{this.state.success ? (

										<div><div class="successfully">¡CREACIÓN EXITOSA!</div>
											<div class="link"></div></div>)
										: null}

								</div>

							</div>

						</div>
						<img class="man" src={man2} />

					</Element>

					<Ticker />

					<Video />

					<Ticker />

					<NFTproject />

					<Element name="token">
						<Ticker />
					</Element>

					<Token />

					<Ticker />

					<TokenBenefits />

					<Ticker />

					<Element name="faq">
						<FAQ />
					</Element>

					<Ticker />

					<Element name="rm">
						<Roadmap />
					</Element>

					<Footer />

				</div>

				<div class="translate"><img onClick={website} src={translate} /></div>

				<Link id="sticky-button" to="headers" spy={true} smooth={true} duration={550}> <img class="stickyBtn2" src={up} /> </Link>


			</div>)
	}
}

export default App;

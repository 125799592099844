import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import transparent from '../assets/transparent.png';
import passport from '../assets/passport.png';
import placeholder from '../assets/placeholder.png';
import man1 from '../assets/man1.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Mission extends Component {

    render() {
        return (

            <div class="boxWrap2Story">

                <div class="storyH">NUESTRA MISIÓN</div>

                <div class="about">
                    <div class="missionMain">
                        <img src={transparent} />
                        <p></p>
                        <div class="missionCon">
                            <p>Tecnología</p>
                            <div>Transforma tus recuerdos en activos digitales únicos que pueden ser compartidos, coleccionados e intercambiados con otros.</div>
                        </div>
                    </div>

                    <div class="missionMain">
                        <img src={placeholder} />
                        <p></p>
                        <div class="missionCon">
                            <p>Puntos de venta</p>
                            <div>Habrá quioscos digitales ubicados en destinos turísticos populares para ofrecer puntos de venta exclusivos para nuestras postales NFT.</div>
                        </div>
                    </div>

                    <div class="missionMain">
                        <img src={passport} />
                        <p></p>
                        <div class="missionCon">
                            <p>Recuerdo intangible</p>
                            <div>Con Postee, obtienes lo mejor del mundo digital: un recuerdo intangible que captura la esencia de tus viajes como un activo digital que se puede compartir y atesorar para siempre.</div>
                        </div>
                    </div>
                </div>
                <img class="man" src={man1} />

            </div>
        )
    }
}

export default Mission;


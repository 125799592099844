import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';

class Ticker extends Component {


    render() {

        return (

            <div class="ticker-wrap">
            <div class="ticker">
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
              <div class="ticker__item">Postee - Recuerdo NFT</div>
            </div>
            </div>)
    }
}

export default Ticker;
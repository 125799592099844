import React, { Component } from 'react';

class FAQ extends Component {

  render() {
    return (

      <div id="faq" class="faqbg">
        <div class="storyH">FAQ</div>

        <div class="accordin reveal">

        <details >
        <summary>¿Es su proyecto SAFU?</summary>
        <div class="faq__content">
          <p>Sí, nuestro proyecto ha recibido el distintivo KYC de Pinksale y el distintivo SAFU de Coinsult.</p>
        </div>
      </details>
      <details>
        <summary>¿Cómo puedo contactar al equipo?</summary>
        <div class="faq__content">
          <p>La mejor forma de contactar a nuestro equipo es a través de Telegram. Únete a nuestro grupo y haz una pregunta, estaremos encantados de responder.</p>

        </div>
      </details>
      <details>
        <summary>¿Cómo puedo comprar tokens Postee (POST)?</summary>
        <div class="faq__content">
          <p>Los tokens Postee (POST) estarán disponibles en el lanzamiento justo en la plataforma confiable de Pinksale.</p>

        </div>
      </details>

      <details>
        <summary>¿Los tokens Postee (POST) estarán disponibles en el lanzamiento justo en la plataforma confiable de Pinksale?</summary>
        <div class="faq__content">
          <p>Sí, el código fuente de nuestro token ha sido minuciosamente revisado por Coinsult.</p>

        </div>
      </details>
    </div>

      </div>


    )
  }
}

export default FAQ;


import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import Carousel from './carousel';
import img2 from '../assets/img2.jpg';
import img3 from '../assets/img3.jpg';
import img4 from '../assets/img4.jpg';
import img5 from '../assets/img5.jpg';
import img6 from '../assets/img6.jpg';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class NFTproject extends Component {

    render() {
        return (

            <div class="boxWrap2NFT">

                <div class="storyH" >Cómo funciona Postee</div>

                <div class="about2">
                    <div class="imgAndConMain">
                        <div class="imgAndCon">
                            <img src={img3} />
                            <div>La persona se da cuenta de que es una máquina de recuerdos digitales.</div>
                        </div>

                        <div class="imgAndCon">
                            <img src={img2} />
                            <div>Lo único que tienes que hacer es escanear el código QR que se muestra en la pantalla del quiosco. Aparecerá una notificación en la pantalla confirmando que has comprado un NFT.</div>
                        </div>
                    </div>

                    <div class="imgAndConMain">
                        <div class="imgAndCon">
                            <img src={img3} />
                            <div>El quiosco Postee llama la atención del turista.</div>
                        </div>

                        <div class="imgAndCon">
                            <img src={img4} />
                            <div>Lo único que tienes que hacer es escanear el código QR que se muestra en la pantalla del quiosco. Aparecerá una notificación en la pantalla confirmando que has comprado un NFT.</div>
                        </div>
                    </div>

                    <div class="imgAndConMain">
                        <div class="imgAndCon">
                            <img src={img5} />
                            <div>La persona se da cuenta de que es una máquina de recuerdos digitales.</div>
                        </div>

                        <div class="imgAndCon">
                            <img src={img6} />
                            <div>El NFT ahora se mostrará en el monedero digital del teléfono del turista.</div>
                        </div>
                    </div>

                </div>

                <Carousel />

            </div>
        )
    }
}

export default NFTproject;

